import _ from 'lodash';

// Takes in unitForQuantity and returns displayable units
export const unitConverter = (unitForQuantity: string) => {
  return unitForQuantity === 'g' ? 'Kg' : 'un';
};

// Converts quantity into locale specific value taking into account quantity
export const quantityConverter = (
  quantity: number,
  unitForQuantity: string,
) => {
  if (quantity == null) {
    return '- -';
  }
  if (quantity < 1000) {
    return `${quantity}${unitForQuantity}`;
  }
  return (
    parseFloat(
      unitForQuantity === 'g' ? quantity / 1000 : quantity,
    ).toLocaleString('pt-BR') + unitConverter(unitForQuantity)
  );
};

//Convert g and un to a unique string with border cases

export const kgAndUnQuantityConverter = (
  weight: number,
  weightUnit: string,
  unit: number,
) => {
  let quantities = [];
  if (weight) {
    quantities.push(quantityConverter(weight, weightUnit));
  }
  if (unit) {
    quantities.push(quantityConverter(unit, 'un'));
  }
  return arrayToStringWithReadableSeparators(quantities, null, '+');
};

// Takes a string and return a truncated version
export const truncateString = (originalString: string, maxLength: number) => {
  if (originalString.length > maxLength) {
    return originalString.substr(0, maxLength - 1) + '...';
  }

  return originalString;
};

export const arrayToStringWithReadableSeparators = (
  array,
  intermediaryDelimitier = ',',
  lastDelimitier = 'e',
) => {
  let string = '';
  array.map((elem, index, array) => {
    string += `${elem}${
      index === array.length - 2
        ? ` ${lastDelimitier} `
        : index === array.length - 1
        ? ''
        : `${intermediaryDelimitier} `
    }`;
  });
  return string;
};

// Convert minutes in hh:mm format
export const minutesToHoursAndMinutes = time => {
  const hours = Math.trunc(time / 60);
  const minutes = Math.abs(time % 60);
  return `${_.padStart(hours, 2, 0)}:${_.padStart(minutes, 2, 0)}`;
};

//cities
export const citiesNamesHelper = [
  'Camaçari',
  'Candeias',
  'Dias Dávila',
  'Entre Rios',
  'Lauro de Freitas',
  'Mata de São João',
  'Salvador',
  'Simões Filho',
];

export const hourAndMinutesToMinutes = time => {
  const [hours, minutes] = time.split(':');
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};

export const isTimeWithinBounds = (time, startTime, endTime) => {
  return (
    hourAndMinutesToMinutes(time) >= hourAndMinutesToMinutes(startTime) &&
    hourAndMinutesToMinutes(time) <= hourAndMinutesToMinutes(endTime)
  );
};

export const distributionCenterGeoLocation = distributionCenter => {
  const distributionCenters = {
    pituba: { lat: -13.007795, lng: -38.461397 },
    default: { lat: -13.007795, lng: -38.461397 },
  };
  return (
    distributionCenters[distributionCenter] || distributionCenters['default']
  );
};

export const priceConverter = value => {
  return parseFloat(value).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const convertPipelinesTitle = (state: string) => {
  const statesTitles: { [key: string]: string } = {
    lost: 'Leads perdidos',
    qualified: 'Leads qualificados',
    contacted: 'Leads contactados',
    close_to_purchase: 'Clientes próximos do dia de compra',
    passed_purchase_date: 'Clientes que excederam o dia de compra',
    in_day: 'Clientes em dia',
  };

  return statesTitles[state] || '';
};
