import React, { useState } from 'react';
// Styles
import './BusinessHeader.css';
// Icons
import { search, userCircle } from '../images';

const BusinessHeader = ({ pageName }) => {
  const [searchQuery, setSearchQuery] = useState();

  return (
    <header className="business-header">
      <div className="business-header-content">
        <h1 className="business-header-page-name">{pageName}</h1>
        <div className="business-header-input-container">
          <img
            src={search}
            alt="Buscar"
            className="business-header-input-search-icon"
          ></img>
          <input
            type="text"
            placeholder="Pesquisar..."
            onChange={event => setSearchQuery(event.target.value)}
          />
        </div>
        <img src={userCircle} alt="Usuario"></img>
      </div>
    </header>
  );
};
export default BusinessHeader;
