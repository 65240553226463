// @FLow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Components
import PipeLine from '../components/PipeLine';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
// Styles
import './SalesFunnel.css';
// Actions
import { getPipelinesItemsAction, movePipelineItemAction } from '../actions';
// Selectors
import { pipelinesItemsSelector } from '../selectors';
// Types
import { IPipelineItem } from '../types/pipeline';

type Props = {
  pipelines: IPipelineItem[],
  getPipelinesItems: () => void,
  movePipelineItem: (
    itemId: number,
    sourcePipeline: string,
    targetPipeline: string,
  ) => void,
};

const SalesFunnel = ({
  pipelines,
  getPipelinesItems,
  movePipelineItem,
}: Props) => {
  useEffect(() => {
    getPipelinesItems();
  }, []);

  if (!pipelines) {
    return <div>Loading...</div>;
  }

  const businessLeads = [
    { state: 'lost' },
    { state: 'qualified' },
    { state: 'contacted' },
    { state: 'close_to_purchase' },
    { state: 'passed_purchase_date' },
    { state: 'in_day' },
  ];

  const pipelinesMap: Record<string, IPipelineItem[]> = {
    lost: [],
    qualified: [],
    contacted: [],
    close_to_purchase: [],
    passed_purchase_date: [],
    in_day: [],
  };

  businessLeads.forEach(lead => {
    pipelinesMap[lead.state] = pipelines.filter(
      item => item.leadState === lead.state,
    );
  });

  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    const sourcePipeline = source.droppableId;
    const targetPipeline = destination.droppableId;

    if (sourcePipeline !== targetPipeline) {
      movePipelineItem(draggableId, sourcePipeline, targetPipeline);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <section className="sales-funnel-main-container">
        {Object.entries(pipelinesMap).map(([pipeline, items]) => (
          <Droppable droppableId={pipeline} key={pipeline}>
            {provided => (
              <div
                className="sales-funnel-pipeline-container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <PipeLine pipeline={pipeline} pipelineItems={items} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </section>
    </DragDropContext>
  );
};

const mapStateToProps = state => ({
  pipelines: pipelinesItemsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getPipelinesItems: () => dispatch(getPipelinesItemsAction()),
  movePipelineItem: (itemId, sourcePipeline, targetPipeline) =>
    dispatch(movePipelineItemAction(itemId, sourcePipeline, targetPipeline)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesFunnel);
