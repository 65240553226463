// @flow
/**
 * BUSINESS MODULE EXPORTS
 * This is the "interface" file for this module.
 * You should only use exports from this file.
 */

/**
 * Imports
 * =======
 * Import exports from files inside
 * the module
 */
import reducer from './reducers';
import SalesFunnel from './containers/SalesFunnel';
import SideBar from './components/SideBar';
import BusinessLayout from './containers/BusinessLayout';

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */
export default reducer;
export { SalesFunnel, SideBar, BusinessLayout };
