// @flow
import { authGet, authPost, getToken, getUserId } from '../../common/api';
import { normalize } from 'normalizr';
import humps from 'humps';
import * as schema from './schema';

/**
 * LOGIN
 * Logins in a user, returning object with JWT and User info
 */
export async function login(username: string, password: string) {
  const body = { username, password };
  const response = await authPost(`/login`, body);
  if (!response.ok) throw new Error(response.status);

  // Normalize data
  const data = await response.json().then(b => humps.camelizeKeys(b));
  const token = data.token;
  const id = data.employee.id;
  const roles = data.employee.roles;
  const normalized = normalize(data.employee, schema.employee);

  try {
    // Add items to sessionStorage, not persistent
    sessionStorage.setItem('access-token', token);
    sessionStorage.setItem('id', id);
  } catch (err) {
    console.error(err);
  }
  return normalized;
}

/**
 * LOGOUT
 */
export async function logout() {
  sessionStorage.removeItem('access-token');
  return;
}

/**
 * Validate token
 */
// TODO: Should we do something else? currently we just check if we have a token

export async function validateToken() {
  const id = getUserId();
  const response = await authGet(`/employees/${id}`);
  const data = await response.json().then(b => humps.camelizeKeys(b));
  const normalized = normalize(data, schema.employee);
  return normalized;
}
