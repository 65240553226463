// @flow
/**
 * Imports
 * =======
 * Import exports from files inside
 * the module
 */

import reducer from './reducers';
import routeConnector from './connectors/routeConnector';
import RoutePage from './containers/RoutePage';
/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */
export {};
export default reducer;

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */
export { RoutePage };
/**
 * HOCs and Connectors
 * ===================
 * Higher order components and connectors wrap the children component
 * and add behavior or props that depend on the internal user state.
 */

export { routeConnector };
