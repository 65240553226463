import React from 'react';
// Images
import unauthorizedImage from '../images/unauthorized-frame.svg';
// Styles
import './Unauthorized.css';

const Unauthorized = () => {
  return (
    <section className="unauthorized-main-container">
      <img src={unauthorizedImage} alt="Acesso não autorizado"></img>
      <div className="unauthorized-message-container">
        <h1 className="unauthorized-title">Você não tem acesso.</h1>
        <p className="unauthorized-text">
          Essa área não pode ser visualizada, tente entrar em contato com seu
          administrador.
        </p>
      </div>
    </section>
  );
};
export default Unauthorized;
