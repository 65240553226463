// @flow
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { translateAasm, visitStates, truncateString } from '../../common';
// Components
import Modal from 'react-modal';
import VisitDetailsModal from '../components/VisitDetailsModal';
import { Draggable } from 'react-beautiful-dnd';
import NaturalDragAnimation from 'natural-drag-animation-rbdnd';
import { Button } from '../../common';
import DatePicker from 'react-datepicker';
import VisitItemAddressEditor from '../components/VisitItemAddressEditor';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import distance from '@turf/distance';
//Style
import './VisitItem.css';
//Helpers
import {
  quantityConverter,
  distributionCenterGeoLocation,
} from '../../common/commonHelpers';
import EditableTaskItem from '../components/EditableTaskItem';
// Composers
import { connect } from 'react-redux';
//Actions
import * as Actions from '../actions';
//Selectors
import * as Selectors from '../selectors';
import { ReviewAddressForm } from '../components/ReviewAddressForm';

//Type
const shipmentTypes = {
  scheduled: 'Agendada',
  takeout: 'Retirada',
};
const VisitItem = ({
  index,
  visitId,
  visitById,
  handleEditTimeOfVisit,
  taskById,
  addressById,
  timeWindowById,
  routeById,
  driverById,
  vehicleById,
  handleGetLocationsByVisit,
  locationsByVisitId,
  handleCreateTask,
  handleCreateOrEditVisitAddress,
  selectedVisitId,
  handleDeleteVisit,
  handleEditTask,
  handleDeleteTask,
  handleCreateVisit,
  visitLoading,
  addressLoading,
  timeWindowLoading,
  taskLoading,
  isDragDisabled,
  isAvailableUpdate,
  onVisitClick,
  autoRoutingInProgress,
  isSelectedToVirtualVisit,
  handleGetVisit,
}) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');

  const visit = visitById(visitId);
  const timeWindow = timeWindowById(visit.timeWindow);
  const address = addressById(visit.address) ? addressById(visit.address) : {};
  const tasks = visit && visit.tasks.map(task => taskById(task));
  const shipmentTask = tasks.find(task => task.shipmentType);
  const shipmentType = shipmentTask && shipmentTask.shipmentType;
  // Checks that the visit is valid. Invalid visits should not be allowed in routes, etc
  const isValidVisit = () => {
    return !(_.isEmpty(address) || _.isEmpty(visit.tasks));
  };

  const isWithinOneHourToDelivery = () => {
    const endTime = new Date(timeWindow.endTime);
    const now = new Date();
    const timeDifference = endTime - now;
    const oneHour = 60 * 60 * 1000;
    const oneMinute = 60 * 1000;
    return timeDifference <= oneHour && timeDifference >= -oneMinute;
  };

  const isNearDeliveryEndTime = isWithinOneHourToDelivery();

  const isInvalidVisitOrTooFar = React.useMemo(() => {
    return (
      _.isEmpty(address) ||
      distance(
        [
          distributionCenterGeoLocation('pituba').lat,
          distributionCenterGeoLocation('pituba').lng,
        ],
        [address.latitude, address.longitude],
        { units: 'kilometers' },
      ) > 100
    );
  }, [address, address.latitude, address.longitude]);
  const memoAddres = React.useMemo(() => {
    return _.isEmpty(address)
      ? 'Sem Endereço'
      : address.neighborhood + ' - ' + address.city;
  }, [address]);
  const memoTimeWindow = React.useMemo(() => {
    return _.isEmpty(timeWindow)
      ? 'Sem Tempos'
      : '(' +
          moment(timeWindow.startTime).utcOffset('-0300').format('DD/MM') +
          ') ' +
          moment(timeWindow.startTime).utcOffset('-0300').format('LT') +
          ' a ' +
          '(' +
          moment(timeWindow.endTime).utcOffset('-0300').format('DD/MM') +
          ') ' +
          moment(timeWindow.endTime).utcOffset('-0300').format('LT');
  });

  const handleConfirmCopyVisit = address => {
    const decision = window.confirm(
      'Tem certeza que deseja efetuar uma cópia dessa visita? Em caso afirmativo, lembre-se de criar as tarefas e inserir outras informações necessárias para execução da visita.',
    );

    decision && handleCreateVisit({ addressId: visit.addressId, task: {} });
  };

  const handleRequestNewReview = reviewObservation => {
    if (!reviewObservation) return;
    handleCreateOrEditVisitAddress(
      { id: address.id, reviewObservation },
      visit.id,
    );
  };

  return (
    <Draggable
      draggableId={`visit-${visit.id}`}
      index={index}
      isDragDisabled={
        isDragDisabled ||
        !isValidVisit() ||
        !(!visit.state || visit.state === 'pending')
      }
    >
      {(provided, snapshot) => (
        <NaturalDragAnimation
          style={provided.draggableProps.style}
          snapshot={snapshot}
        >
          {style => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
              onClick={autoRoutingInProgress ? () => {} : () => onVisitClick()}
            >
              {infoModalIsOpen && (
                <VisitDetailsModal
                  visitId={visitId}
                  visitById={visitById}
                  addressById={addressById}
                  taskById={taskById}
                  timeWindowById={timeWindowById}
                  routeById={routeById}
                  driverById={driverById}
                  vehicleById={vehicleById}
                  handleGetLocationsByVisit={handleGetLocationsByVisit}
                  handleGetVisit={handleGetVisit}
                  locationsByVisitId={locationsByVisitId}
                  isOpen={infoModalIsOpen}
                  handleModalClose={() => setInfoModalIsOpen(false)}
                />
              )}

              {editModalIsOpen && (
                <Modal
                  isOpen={editModalIsOpen}
                  className="visit-modal-style"
                  onRequestClose={() => setEditModalIsOpen(false)}
                  ariaHideApp={false}
                >
                  <div className="visit-item-wrapper">
                    <div className="visit-item-header">
                      <p className="visit-item-main-editor-title">
                        Editor de Visita
                      </p>

                      <div className="visit-item-delete-visit-button">
                        <Button
                          icon={'fa fa-trash'}
                          disabled={visit.tasks.length !== 0}
                          buttonType={'button-secondary'}
                          onClick={() => handleDeleteVisit(visit.id)}
                          loading={visitLoading}
                        />
                      </div>
                    </div>

                    <div className="visit-item-main-address-editor">
                      <div className="visit-item-main-datepicker-selector">
                        <p className="visit-item-main-editor-subtitle">
                          Informações de Data/Hora
                        </p>
                        {/* Start Time Selector */}
                        <div className="visit-item-loading">
                          {timeWindowLoading ? (
                            <i
                              className="fa fa-spinner fa-pulse fa-1x"
                              aria-hidden="true"
                            />
                          ) : null}
                        </div>
                        <div className="visit-item-main-datepicker-selector-line">
                          <p>Início: </p>
                          <DatePicker
                            selected={moment(
                              timeWindow && timeWindow.startTime,
                            )}
                            showTimeSelect
                            onChange={e =>
                              handleEditTimeOfVisit(timeWindow, {
                                startTime: e.format(),
                              })
                            }
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Horário"
                            minDate={new Date()}
                            dateFormat="DD/MM/YY HH:mm"
                            disabled={timeWindowLoading}
                          />
                          <p>Fim: </p>
                          <DatePicker
                            selected={moment(timeWindow && timeWindow.endTime)}
                            showTimeSelect
                            onChange={e =>
                              handleEditTimeOfVisit(timeWindow, {
                                endTime: e.format(),
                              })
                            }
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Horário"
                            minDate={new Date()}
                            dateFormat="DD/MM/YY HH:mm"
                            disabled={timeWindowLoading}
                          />
                        </div>
                      </div>
                      <VisitItemAddressEditor
                        handleCreateOrEditAddress={address =>
                          handleCreateOrEditVisitAddress(address, visit.id)
                        }
                        address={addressById(visit.address)}
                        loading={addressLoading}
                      />
                    </div>

                    <ReviewAddressForm
                      isExistentAddress={!!address.id}
                      hasPendingReview={!!address.reviewObservation}
                      onRequestNewReview={handleRequestNewReview}
                      loading={addressLoading}
                    />

                    <div className="visit-item-main-task-actions">
                      <div className="visit-item-main-task">
                        <p className="visit-item-main-editor-subtitle">
                          Informação de Tarefas
                        </p>
                        <div className="visit-item-main-task-add-button">
                          <button
                            disabled={taskLoading}
                            onClick={() =>
                              // If we have no orderNumber we should create a genericTask
                              {
                                if (orderNumber) {
                                  handleCreateTask({
                                    visitId: visit.id,
                                    type: 'deliver_task',
                                    orderNumber: orderNumber,
                                  });
                                } else {
                                  handleCreateTask({
                                    visitId: visit.id,
                                    type: 'generic_task',
                                    instructions: '',
                                  });
                                }
                              }
                            }
                            className="visit-card-add-task
                        "
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <div className="visit-item-list-of-tasks">
                        {visit.tasks &&
                          visit.tasks.map(taskId => (
                            <EditableTaskItem
                              key={taskId}
                              task={taskById(taskId)}
                              handleEditTask={params =>
                                handleEditTask(taskId, params)
                              }
                              handleDeleteTask={handleDeleteTask}
                            />
                          ))}
                        {!visit.tasks.length ? (
                          <div>
                            Nenhuma tarefa associada. Para associar a um pedido
                            digite o número e pressione +.{' '}
                            <input
                              placeholder="Ex: R123456"
                              value={orderNumber}
                              onChange={e => setOrderNumber(e.target.value)}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Modal>
              )}

              <div
                className={`visit-card ${
                  selectedVisitId === visit.id ? 'visit-card-alert-card' : ''
                } ${
                  isNearDeliveryEndTime ? 'visit-card-near-end-time-alert' : ''
                } `}
                id={`visit-scroll-${visit.id}`}
              >
                <span
                  data-tip=""
                  data-for={`visit-item-tooltip-info-edit-button-${visit.id}`}
                >
                  <button
                    onClick={() => setEditModalIsOpen(true)}
                    className={`visit-card-internal-button ${
                      selectedVisitId === visit.id
                        ? 'visit-card-alert-card'
                        : ''
                    } ${
                      isNearDeliveryEndTime
                        ? 'visit-card-near-end-time-alert'
                        : ''
                    }`}
                    disabled={!isAvailableUpdate}
                  >
                    <ReactTooltip
                      id={`visit-item-tooltip-info-edit-button-${visit.id}`}
                    >
                      <p>{`${
                        isAvailableUpdate
                          ? 'Editar Rota'
                          : 'Não é possível editar uma visita de um dia anterior ao atual ou que já tenha sido planejada'
                      }`}</p>
                    </ReactTooltip>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </span>

                <span
                  data-tip=""
                  data-for={`visit-item-tooltip-info-see-information-button-${visit.id}`}
                >
                  <button
                    onClick={() => setInfoModalIsOpen(true)}
                    className={`visit-card-internal-button ${
                      selectedVisitId === visit.id
                        ? 'visit-card-alert-card'
                        : ''
                    } ${
                      isNearDeliveryEndTime
                        ? 'visit-card-near-end-time-alert'
                        : ''
                    } `}
                    style={{ color: 'black' }}
                  >
                    <ReactTooltip
                      id={`visit-item-tooltip-info-see-information-button-${visit.id}`}
                    >
                      <p>{'Informaçoes sobre essa visita'}</p>
                    </ReactTooltip>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </button>
                </span>
                <span
                  data-tip=""
                  data-for={`visit-item-tooltip-info-select-virtual-visit-${visit.id}`}
                >
                  <input
                    type="checkbox"
                    id={`selected-visit-${visit.id}`}
                    value={visit.id}
                    checked={isSelectedToVirtualVisit}
                    disabled={
                      isDragDisabled ||
                      !isValidVisit() ||
                      !(!visit.state || visit.state === 'pending')
                    }
                    style={{ display: autoRoutingInProgress ? '' : 'none' }}
                    className="visit-card-internal-button"
                    onChange={
                      autoRoutingInProgress ? () => onVisitClick() : () => {}
                    }
                  ></input>
                  <ReactTooltip
                    id={`visit-item-tooltip-info-select-virtual-visit-${visit.id}`}
                  >
                    <p>{'Selecionar visita para roteamento'}</p>
                  </ReactTooltip>
                </span>

                {visit.state === 'failed' ? (
                  <span
                    data-tip=""
                    data-for={`visit-item-tooltip-info-copy-button-${visit.id}`}
                  >
                    <button
                      onClick={() => handleConfirmCopyVisit(visit.addressId)}
                      className={`visit-card-internal-button ${
                        selectedVisitId === visit.id
                          ? 'visit-card-alert-card'
                          : ''
                      } ${
                        isNearDeliveryEndTime
                          ? 'visit-card-near-end-time-alert'
                          : ''
                      } `}
                      style={{ color: 'black' }}
                    >
                      <ReactTooltip
                        id={`visit-item-tooltip-info-copy-button-${visit.id}`}
                      >
                        <p>{'Copiar Visita'}</p>
                      </ReactTooltip>
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </button>
                  </span>
                ) : null}

                <div
                  className={`visit-card-info-line ${
                    isInvalidVisitOrTooFar ? 'visit-item-color-failed' : ''
                  }`}
                >
                  <i className="fa fa-map-signs" aria-hidden="true"></i>
                  <p>{`${memoAddres}`}</p>{' '}
                </div>

                <div className="visit-card-info-line">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  <p>
                    {`${memoTimeWindow}`}{' '}
                    {visit.visitedAt || visit.signedAt ? (
                      <span className="visit-item-color-success">
                        - (
                        {moment(visit.visitedAt || visit.signedAt)
                          .utcOffset('-0300')
                          .format('LT')}
                        )
                      </span>
                    ) : null}
                  </p>
                </div>

                <div className="visit-card-info-line-additional-info">
                  {visit.routeId ? (
                    <span
                      data-tip=""
                      data-for="visit-item-tooltip-info-time-to-next-visit"
                    >
                      <ReactTooltip id="visit-item-tooltip-info-time-to-next-visit">
                        <p>Tempo até a Entrega</p>
                      </ReactTooltip>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      <p>{`${
                        '+' + Math.floor(visit.timeToThisVisit / 60) + ' min'
                      }`}</p>
                    </span>
                  ) : (
                    <span
                      data-tip=""
                      data-for="visit-item-tooltip-info-estimated-time-at-stop"
                    >
                      <ReactTooltip id="visit-item-tooltip-info-estimated-time-at-stop">
                        <p>Tempo Estimado em Visita</p>
                      </ReactTooltip>
                      <i
                        className="fa fa-hourglass-half"
                        aria-hidden="true"
                      ></i>
                      <p>{`${
                        Math.floor(visit.estimatedTimeAtStop / 60) + ' min'
                      }`}</p>
                    </span>
                  )}
                  <span
                    data-tip=""
                    data-for="visit-item-tooltip-info-time-to-prepare"
                  >
                    <ReactTooltip id="visit-item-tooltip-info-time-to-prepare">
                      <p>Tempo de Preparação</p>
                    </ReactTooltip>

                    <i className="fa fa-bolt" aria-hidden="true"></i>
                    <p>{`${visit.estimatedPreparationTime + ' min'}`}</p>
                  </span>
                  {shipmentType && (
                    <span
                      data-tip=""
                      data-for="visit-item-tooltip-info-delivery-type"
                    >
                      <ReactTooltip id="visit-item-tooltip-info-delivery-type">
                        <p>{shipmentTypes[shipmentType] || 'Expressa'}</p>
                      </ReactTooltip>
                      <i className="fa fa-truck" aria-hidden="true"></i>
                    </span>
                  )}

                  <span data-tip="" data-for="visit-item-tooltip-info-weight">
                    <ReactTooltip id="visit-item-tooltip-info-weight">
                      <p>Peso</p>
                    </ReactTooltip>
                    <i className="fa fa-balance-scale" aria-hidden="true"></i>
                    <p>{quantityConverter(visit.weight, 'g')}</p>
                  </span>
                  {visit.routeId ? (
                    <span
                      data-tip=""
                      data-for={`visit-item-tooltip-info-state-${visit.id}`}
                    >
                      <ReactTooltip
                        id={`visit-item-tooltip-info-state-${visit.id}`}
                      >
                        <p>{`Estado: ${visitStates(visit.state)}`}</p>
                      </ReactTooltip>
                      <div className={`visit-item-color-${visit.state}`}>
                        {visit.state === 'pending' ? (
                          <i className="fa fa-square-o" aria-hidden="true"></i>
                        ) : visit.state === 'success' ? (
                          <i
                            className="fa fa-check-square-o"
                            aria-hidden="true"
                          ></i>
                        ) : visit.state === 'in_progress' ? (
                          <i
                            className="fa fa-hourglass-half"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o"
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                    </span>
                  ) : null}
                </div>

                <div className="visit-card-info-line-tasks">
                  {visit.tasks &&
                    visit.tasks.map(taskId => {
                      const task = taskById(taskId);
                      switch (task.type) {
                        case 'GenericTask':
                          return (
                            <p key={taskId}>
                              {taskId} - {truncateString(task.instructions, 40)}{' '}
                              {task.failedAt ? (
                                <span className="visit-item-color-failed">
                                  - {task.failedReason}
                                </span>
                              ) : null}
                            </p>
                          );
                        case 'DeliverTask':
                          return (
                            <p key={taskId}>
                              <Link
                                key={taskId}
                                to={`/fulfillments/${task.orderNumber}`}
                              >
                                {task.orderNumber}
                                {task.paymentType
                                  ? ` - ${task.paymentType}`
                                  : ''}{' '}
                                - {translateAasm(task.fulfillmentState)}
                                {task.fulfillmentPosition &&
                                  ` - Posição ${task.fulfillmentPosition}`}
                              </Link>{' '}
                              {task.failedAt ? (
                                <span className="visit-item-color-failed">
                                  - {task.failedReason}
                                </span>
                              ) : null}
                            </p>
                          );
                        default:
                          return (
                            <p key={taskId}>
                              {taskId}
                              {task.failedAt ? (
                                <span className="visit-item-color-failed">
                                  - {task.failedReason}
                                </span>
                              ) : null}
                            </p>
                          );
                      }
                    })}
                  {_.isEmpty(visit.tasks) && (
                    <p
                      className={`visit-card-info-line-tasks visit-item-color-failed`}
                    >
                      Sem Tarefa
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </NaturalDragAnimation>
      )}
    </Draggable>
  );
};

const mapStateToProps = state => {
  return {
    visitById: id => Selectors.visitByIdSelector(state, id),
    taskById: id => Selectors.taskByIdSelector(state, id),
    addressById: id => Selectors.addressByIdSelector(state, id),
    timeWindowById: id => Selectors.timeWindowByIdSelector(state, id),
    routeById: id => Selectors.routeByIdSelector(state, id),
    driverById: id => Selectors.driverByIdSelector(state, id),
    vehicleById: id => Selectors.vehicleByIdSelector(state, id),
    locationsByVisitId: id => Selectors.locationsByVisitIdSelector(state, id),
    visitLoading: Selectors.visitsLoadingSelector(state),
    addressLoading: Selectors.addressLoadingSelector(state),
    timeWindowLoading: Selectors.timeWindowSelector(state),
    taskLoading: Selectors.taskSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleEditTimeOfVisit: (timeWindow, timeWindowParam) => {
      if (!timeWindow.shippingRateId) {
        dispatch(Actions.editTimeWindow(timeWindow.id, timeWindowParam));
      } else {
        dispatch(Actions.editShippingRate(timeWindow, timeWindowParam));
      }
    },

    handleCreateTask: taskParams => {
      dispatch(Actions.newTask(taskParams));
    },
    handleCreateOrEditVisitAddress: (addressParams, visitId) => {
      dispatch(Actions.newAddress(addressParams, visitId));
    },
    handleEditTask: (id, taskParams) => {
      dispatch(Actions.editTask(id, taskParams));
    },
    handleDeleteVisit: visitId => {
      dispatch(Actions.deleteVisit(visitId));
    },
    handleDeleteTask: taskId => {
      dispatch(Actions.deleteTask(taskId));
    },
    handleGetLocationsByVisit: visitId => {
      dispatch(Actions.getLocationsByVisit(visitId));
    },
    handleGetVisit: visitId => {
      dispatch(Actions.findVisit(visitId));
    },
    handleCreateVisit: visitParams => {
      dispatch(Actions.newVisit(visitParams));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitItem);
