// @Flow
import React from 'react';
// Components
import { Route } from 'react-router-dom';
import { ProtectedRoute } from '../../common';
import Layout from './Layout';

type Props = {
  component: component,
  auth: boolean,
  requiredRoles: [],
  employeeRoles: [],
};

const CustomRoute = ({
  component,
  auth,
  requiredRoles,
  employeeRoles,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <ProtectedRoute
            component={component}
            auth={auth}
            requiredRoles={requiredRoles}
            employeeRoles={employeeRoles}
            {...props}
          />
        </Layout>
      )}
    />
  );
};

export default CustomRoute;
