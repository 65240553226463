// Types
import { IPipelineItem } from '../types/pipeline';

export const mockedPipelinesMap: IPipelineItem[] = [
  {
    id: 1,
    leadState: 'lost',
    lastContacted: new Date('2024-01-01'),
    userId: 101,
    employeeId: 201,
  },
  {
    id: 2,
    leadState: 'lost',
    lastContacted: new Date('2024-02-01'),
    userId: 102,
    employeeId: 202,
  },
  {
    id: 3,
    leadState: 'qualified',
    lastContacted: new Date('2024-03-01'),
    userId: 103,
    employeeId: 203,
  },
  {
    id: 4,
    leadState: 'qualified',
    lastContacted: new Date('2024-04-01'),
    userId: 104,
    employeeId: 204,
  },
  {
    id: 5,
    leadState: 'contacted',
    lastContacted: new Date('2024-05-01'),
    userId: 105,
    employeeId: 205,
  },
  {
    id: 6,
    leadState: 'contacted',
    lastContacted: new Date('2024-06-01'),
    userId: 106,
    employeeId: 206,
  },
  {
    id: 7,
    leadState: 'close_to_purchase',
    lastContacted: new Date('2024-07-01'),
    userId: 107,
    employeeId: 207,
  },
  {
    id: 8,
    leadState: 'close_to_purchase',
    lastContacted: new Date('2024-08-01'),
    userId: 108,
    employeeId: 208,
  },
  {
    id: 9,
    leadState: 'passed_purchase_date',
    lastContacted: new Date('2024-09-01'),
    userId: 109,
    employeeId: 209,
  },
  {
    id: 10,
    leadState: 'passed_purchase_date',
    lastContacted: new Date('2024-10-01'),
    userId: 110,
    employeeId: 210,
  },
  {
    id: 11,
    leadState: 'in_day',
    lastContacted: new Date('2024-11-01'),
    userId: 111,
    employeeId: 211,
  },
  {
    id: 12,
    leadState: 'in_day',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 13,
    leadState: 'in_day',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 14,
    leadState: 'in_day',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 15,
    leadState: 'in_day',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 16,
    leadState: 'in_day',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 17,
    leadState: 'qualified',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 18,
    leadState: 'qualified',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 19,
    leadState: 'qualified',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 20,
    leadState: 'qualified',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 21,
    leadState: 'qualified',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
  {
    id: 22,
    leadState: 'qualified',
    lastContacted: new Date('2024-12-01'),
    userId: 112,
    employeeId: 212,
  },
];
