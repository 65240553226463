// @flow
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../common';
import { IPipelineItem } from '../types/pipeline';
// Api
import { getPipelineItems } from '../api/index';
// Logger
import { logException } from '../../common';

// PipelinesItems
export const GET_PIPELINES_ITEMS_REQUEST = 'GET_PIPELINES_ITEMS_REQUEST';
export const PIPELINES_ITEMS_SUCCESS = 'PIPELINES_ITEMS_SUCCESS';
export const PIPELINES_ITEMS_FAILURE = 'PIPELINES_ITEMS_FAILURE';

export const getPipelinesItemsRequest = createAction(
  GET_PIPELINES_ITEMS_REQUEST,
);
export const pipelinesItemsSuccess = createAction(PIPELINES_ITEMS_SUCCESS);
export const pipelinesItemsFailure = createAction(PIPELINES_ITEMS_FAILURE);

export const getPipelinesItemsAction = () => {
  return (dispatch: Dispatch) => {
    dispatch(getPipelinesItemsRequest());
    try {
      const response: IPipelineItem[] = getPipelineItems();
      dispatch(pipelinesItemsSuccess(response));
    } catch (err) {
      logException(err);
      dispatch(pipelinesItemsFailure(err));
    }
  };
};

export const MOVE_PIPELINE_ITEM = 'MOVE_PIPELINE_ITEM';

export const movePipelineItemAction = (
  itemId,
  sourcePipeline,
  targetPipeline,
) => {
  return {
    type: MOVE_PIPELINE_ITEM,
    payload: { itemId: parseInt(itemId, 10), sourcePipeline, targetPipeline },
  };
};
