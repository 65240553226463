// @flow
/**
 * EMPLOYEE MODULE EXPORTS
 * This is the "interface" file for this module.
 * You should only use exports from this file.
 */

/**
 * Imports
 * =======
 * Import exports from files inside
 * the module
 */
import reducer from './reducers';
import Validate from './containers/Validate';
import LogoutPage from './containers/LogoutPage';
import LoginPage from './containers/LoginPage';
import LoggedInIndicator from './containers/LoggedInIndicator';
import UnauthenticatedRedirect from './containers/UnauthenticatedRedirect';

/**
 * Reducer
 * =======
 * The default export is the reducer to be integrated in the Redux Store.
 * Its state is defined too, and exported as the type StoreState.
 */
export default reducer;
export type { StoreState } from './types/StoreState';
export { logoutEmployee } from './actions';

/**
 * Selectors
 * =========
 */
export { idSelector as employeeIdSelector } from './selectors';
export { loggedInSelector as employeeLoggedInSelector } from './selectors';
export { rolesSelector as employeeRolesSelector } from './selectors';

/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */
export { Validate, LoggedInIndicator, UnauthenticatedRedirect };

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */
export { LogoutPage, LoginPage };

/**
 * HOCs and Connectors
 * ===================
 * Higher order components and connectors wrap the children component
 * and add behavior or props that depend on the internal user state.
 */

/**
 * Types
 * =====
 * Flow types to be used in other modules, generally with connectors.
 */
