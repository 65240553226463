// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
// Styles
import './SideBar.css';
// Images
import { bigDeliveryIcon, bankNote, user } from '../images';
// Components
import { Link } from 'react-router-dom';

const SideBar = props => {
  const { pathname } = props.location;
  return (
    <aside className="side-bar">
      <Link to={'/'}>
        <img src={bigDeliveryIcon} alt="Voltar para o inicio"></img>
      </Link>
      <Link
        to={'/business/funnel'}
        className={`side-bar-link ${
          pathname == '/business/funnel' ? 'side-bar-link-with-background' : ''
        }`}
      >
        <img src={bankNote} alt="Funil de vendas"></img>
      </Link>
      <Link
        to={'#'}
        className={`side-bar-link ${
          pathname == '/business/contacts'
            ? 'side-bar-link-with-background'
            : ''
        }`}
      >
        <img src={user} alt="Usuario"></img>
      </Link>
    </aside>
  );
};

export default withRouter(SideBar);
