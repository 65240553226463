// @flow
import React, { useState } from 'react';
// Components
import { Link } from 'react-router-dom';
import { LoggedInIndicator } from '../../employee';
import HamburgerMenu from '../containers/HamburgerMenu';
// Styles
import Logo from '../images/LogoHorizontal.png';
import './Header.css';

const Header = () => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  return (
    <div className="header-main">
      <div>
        <Link to={'/fulfillments'}>
          <img className="header-logo" src={Logo} alt={'BigDelivery Logo'} />
        </Link>
        <span className="header-version-information">
          Versão: {process.env.REACT_APP_BUILD_COMMIT}
        </span>
      </div>

      <div className="header-navigation-container">
        <Link to={'/fulfillments/sort'}>Ordenador</Link>
        <Link to={'/routes'}>Roteirizador</Link>
        <Link to={'/statistics'}>Estatísticas</Link>
        <Link to={'/business/funnel'}>Comercial</Link>
        <LoggedInIndicator />
      </div>

      <i
        className="fa fa-bars header-menu"
        aria-hidden="true"
        data-testid="header-hamburger-menu-toggle"
        onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
      />

      {showHamburgerMenu ? (
        <div
          className="hamburger-menu"
          data-testid="header-hamburger-menu-content"
        >
          <div
            className="hamburger-menu-outer"
            onClick={() => setShowHamburgerMenu(false)}
          />
          <HamburgerMenu />
        </div>
      ) : null}
    </div>
  );
};

export default Header;
