// @Flow
import React, { useState } from 'react';
// Types
import { IPipelineItem } from '../types/pipeline';
// Styles
import './PipeLine.css';
// Helpers
import { convertPipelinesTitle } from '../../common';
// Components
import PipelineItem from './PipelineItem';

type Props = {
  pipelineItems: IPipelineItem[],
  pipeline: string,
};

const MAX_ITEMS_TO_SHOW = 6;

const PipeLine = ({ pipelineItems, pipeline }: Props) => {
  const [isButtonToggled, setIsButtonToggled] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(
    pipelineItems.length > MAX_ITEMS_TO_SHOW,
  );

  const toggleShowMore = () => {
    setShowMoreItems(!showMoreItems);
    setIsButtonToggled(!isButtonToggled);
  };

  const items = showMoreItems
    ? pipelineItems.slice(0, MAX_ITEMS_TO_SHOW)
    : pipelineItems;

  return (
    <section
      className={`pipeline-main-container ${
        pipeline === 'lost' ? 'lost-leads-pipeline' : ''
      }  ${pipeline === 'in_day' ? 'in-day-pipeline' : ''}`}
    >
      <div className="pipeline-text-container">
        <h1 className="pipeline-title">{convertPipelinesTitle(pipeline)}</h1>
      </div>
      <div
        className={`pipeline-bottom-content ${
          !showMoreItems ? 'activate-scroll-bar' : ''
        }`}
      >
        <div className="pipeline-items-main-container">
          {items.map((item, index) => (
            <article className="pipeline-items-list" key={index}>
              <PipelineItem pipelineItem={item} index={index} />
            </article>
          ))}
        </div>
        {pipelineItems.length > MAX_ITEMS_TO_SHOW && (
          <button
            className="pipeline-show-more-button"
            onClick={toggleShowMore}
          >
            {!isButtonToggled ? 'Ver mais' : 'Ver menos'}
          </button>
        )}
      </div>
    </section>
  );
};

export default PipeLine;
