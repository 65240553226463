// @Flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// Componets
import Unauthorized from './Unauthorized';

const ProtectedRoute = ({
  auth,
  component: Component,
  requiredRoles = [],
  employeeRoles = [],
  ...rest
}) => {
  const checkEmployeeRoles = (requiredRoles, employeeRoles) => {
    return (
      (requiredRoles && requiredRoles.length === 0) ||
      (employeeRoles &&
        employeeRoles.some(role => requiredRoles.includes(role.slug)))
    );
  };

  const isEmployeeAuthorized = checkEmployeeRoles(requiredRoles, employeeRoles);

  return (
    <Route
      {...rest}
      render={props =>
        isEmployeeAuthorized ? <Component {...props} /> : <Unauthorized />
      }
    />
  );
};

export default ProtectedRoute;
