// @flow
import runtimeEnv from '@avantsoft/js-runtime-env';
const env = runtimeEnv();

const API_ENDPOINT =
  env.REACT_APP_API_URL ||
  'https://fulfillment-backend.bigdelivery-staging.avantsoft.com.br';

const SALES_ENDPOINT =
  env.REACT_APP_SALES_API_URL ||
  'https://sales-backend.bigdelivery-staging.avantsoft.com.br';

export function getToken() {
  return sessionStorage.getItem('access-token');
}

export function getUserId() {
  return sessionStorage.getItem('id');
}

export function isAuthenticated() {
  const accessToken = sessionStorage.getItem('access-token');
  return accessToken != null;
}

function getAuthenticatedHeaders(
  unauthenticatedHeaders: Headers = new Headers(),
) {
  // Get token headers from sessionStorage
  const accessToken = sessionStorage.getItem('access-token');

  if (accessToken != null) {
    unauthenticatedHeaders.append('Authorization', `${accessToken}`);
  }
  return unauthenticatedHeaders;
}

function defaultHandleErrors(response: Response) {
  throw new Error(response.status);
}

export const createAuthRequest =
  (method: 'POST' | 'GET' | 'PUT' | 'DELETE') =>
  async (
    path: string,
    body: {} = {},
    handleErrors: Response => void = defaultHandleErrors,
    headers: Headers = new Headers(),
  ) => {
    const apiHeaders = getAuthenticatedHeaders(headers);
    apiHeaders.append('Content-Type', 'application/json');
    apiHeaders.append('Accept', 'application/json');
    apiHeaders.append('App', 'supervisor-frontend');
    apiHeaders.append('App-Version', process.env.REACT_APP_BUILD_COMMIT);

    let init = {
      method,
      headers: apiHeaders,
    };

    if (body && (init.method === 'POST' || init.method === 'PUT')) {
      init = { ...init, body: JSON.stringify(body) };
    }

    const response = await fetch(`${API_ENDPOINT}${path}`, init);

    if (!response.ok) {
      handleErrors(response);
    }

    return response;
  };

export const authGetSalesXml = async (
  path: string,
  handleErrors: Response => void = defaultHandleErrors,
  headers: Headers = new Headers(),
) => {
  const apiHeaders = getAuthenticatedHeaders(headers);
  apiHeaders.append('Content-Type', 'application/json');
  apiHeaders.append('Accept', 'application/xml');

  let init = {
    method: 'GET',
    headers: apiHeaders,
  };

  const response = await fetch(`${SALES_ENDPOINT}${path}`, init);

  if (!response.ok) {
    handleErrors(response);
  }

  return response;
};

export const authGetSalesReceipt = async (
  path: string,
  handleErrors: Response => void = defaultHandleErrors,
  headers: Headers = new Headers(),
) => {
  const apiHeaders = getAuthenticatedHeaders(headers);

  let init = {
    method: 'GET',
    headers: apiHeaders,
  };

  const response = await fetch(path, init);

  if (!response.ok) {
    handleErrors(response);
  }

  return response;
};

export const authGet = createAuthRequest('GET');
export const authPost = createAuthRequest('POST');
export const authPut = createAuthRequest('PUT');
export const authDelete = createAuthRequest('DELETE');
