// @flow
import { useEffect } from 'react';
// Composers
import { connect } from 'react-redux';
// Selectors
import { loggedInSelector } from '../selectors';
// Actions
import { validate } from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { Dispatch } from '../../common';

type Props = {
  validate: () => void,
  loggedIn: boolean,
};

const Validate = ({ validate }: Props) => {
  useEffect(() => {
    validate();
  }, []);

  return null;
};

const mapStateToProps = (state, ownProps: {}) => {
  return {
    loggedIn: loggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {}) => {
  return {
    validate: () => {
      dispatch(validate());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(Validate);
