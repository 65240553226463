// @flow
/**
 * EMPLOYEE MODULE EXPORTS
 * This is the "interface" file for this module.
 * You should only use exports from this file.
 */

/**
 * Imports
 * =======
 * Import exports from files inside
 * the module
 */

import Button from './components/Button';
import LinePlot from './components/LinePlot';
import InputFieldErrorMessage from './components/InputFieldErrorMessage';
import InputFieldLabel from './components/InputFieldLabel';
import ProtectedRoute from './components/ProtectedRoute';
import CustomRoute from './components/CustomRoute';

import { translateAasm, visitStates, routeStates } from './translationHelpers';
import { logException } from './logHelper';
import qsStringify from './queryHelper';
import ColorBar from './components/ColorBar';
import PlacementPhotoScroller from './components/PlacementPhotoScroller';
import SwitchGroup from './components/SwitchGroup';
import {
  quantityConverter,
  truncateString,
  minutesToHoursAndMinutes,
  citiesNamesHelper,
  hourAndMinutesToMinutes,
  isTimeWithinBounds,
  kgAndUnQuantityConverter,
  distributionCenterGeoLocation,
  priceConverter,
  convertPipelinesTitle,
} from './commonHelpers';
import { notificationHelper } from './notificationHelper';
import { usePrevious } from './usePrevious';
import { isAuthenticated } from './api';
import { ADDRESS_FORM_FIELDS_CONSTRAINTS } from './formsConstants';
import Unauthorized from './components/Unauthorized';
/**
 * Reducer
 * =======
 * The default export is the reducer to be integrated in the Redux Store.
 * Its state is defined too, and exported as the type StoreState.
 */

/**
 * Selectors
 * =========
 */

/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */
export {
  Button,
  LinePlot,
  InputFieldErrorMessage,
  InputFieldLabel,
  ColorBar,
  SwitchGroup,
  PlacementPhotoScroller,
  ProtectedRoute,
  CustomRoute,
  Unauthorized,
};

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */

/**
 * HOCs and Connectors
 * ===================
 * Higher order components and connectors wrap the children component
 * and add behavior or props that depend on the internal user state.
 */

/**
 * Types
 * =====
 * Flow types to be used in other modules, generally with connectors.
 */
export type { State, Action, Store, Dispatch } from './types';

/**
 * Functions
 * ====
 */
export {
  translateAasm,
  visitStates,
  routeStates,
  logException,
  qsStringify,
  quantityConverter,
  truncateString,
  kgAndUnQuantityConverter,
  minutesToHoursAndMinutes,
  citiesNamesHelper,
  notificationHelper,
  usePrevious,
  hourAndMinutesToMinutes,
  isTimeWithinBounds,
  distributionCenterGeoLocation,
  isAuthenticated,
  priceConverter,
  convertPipelinesTitle,
};

/**
 * Forms Constants
 */

export const forms = {
  ADDRESS_FORM_FIELDS_CONSTRAINTS,
};
