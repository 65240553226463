import React from 'react';
import { routes } from './routes';
// Styles
import './App.css';
// Components
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Validate } from './employee';
import { CustomRoute } from './common';
// Composers
import { connect } from 'react-redux';
// Selectors
import { employeeLoggedInSelector, employeeRolesSelector } from './employee';

type Props = {
  loggedIn: boolean,
  roles: [],
};

const App = ({ loggedIn, roles }: Props) => {
  return (
    <BrowserRouter>
      <div id="app">
        <Validate />
        <main>
          <div className="app-container">
            <Switch>
              {routes.map((route, index) => (
                <CustomRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                  auth={loggedIn}
                  requiredRoles={route.requiredRoles}
                  employeeRoles={roles}
                />
              ))}
              {/* If not route matches we should redirect to the login page */}
              <Route component={() => <Redirect to="/login" />} />
            </Switch>
          </div>
        </main>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    loggedIn: employeeLoggedInSelector(state),
    roles: employeeRolesSelector(state),
  };
};

const connector = connect(mapStateToProps);

export default connector(App);
