// @flow
import { combineReducers } from 'redux';
import employee from '../employee';
import fulfillments from '../fulfillments';
import issueSources from '../issue-sources';
import fraud from '../fraud';
import statistics from '../statistics';
import routes from '../routes/index';
import { logoutEmployee } from '../employee';
import business from '../business';

const appReducer = combineReducers({
  employee,
  fulfillments,
  issueSources,
  fraud,
  statistics,
  routes,
  business,
});

const rootReducer = (state, action) => {
  if (action.type === logoutEmployee.toString()) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
