// @Flow
import React from 'react';
// Types
import { IPipelineItem } from '../types/pipeline';
// Styles
import './PipelineItem.css';
// Components
import { Draggable } from '@hello-pangea/dnd';

type Props = {
  pipelineItem: IPipelineItem,
  index: number,
};

const PipelineItem = ({ pipelineItem, index }: Props) => {
  return (
    <Draggable draggableId={String(pipelineItem.id)} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`pipeline-item-container ${
            snapshot.isDragging ? 'dragging' : ''
          }`}
        >
          <div className="pipeline-item-content">
            <h1>{pipelineItem.id}</h1>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default PipelineItem;
