// @flow
import { createSelector } from 'reselect';
import type { StoreState } from '../types/StoreState';

export const thisStateSelector = (state: any): StoreState => state.business;

export const pipelinesItemsSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.pipelinesItems.data,
);
