// @Flow
import React from 'react';
import { withRouter } from 'react-router-dom';
// Components
import SideBar from '../components/SideBar';
import BusinessHeader from '../components/BusinessHeader';
// Styles
import './BusinessLayout.css';

type Props = {
  children: React.ReactNode,
} & RouteComponentProps;

const BusinessLayout = ({ children, location }: Props) => {
  const getPageName = () => {
    const pathName = location.pathname;
    switch (pathName) {
      case '/business/funnel':
        return 'Funil de Vendas';
      case '/business/contacts':
        return 'Contatos';
      default:
        return 'Unknown Page';
    }
  };
  return (
    <div className="business-layout">
      <SideBar />
      <div className="business-layout-content ">
        <BusinessHeader pageName={getPageName()} />
        {children}
      </div>
    </div>
  );
};
export default withRouter(BusinessLayout);
