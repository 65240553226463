// @Flow
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
// Actions
import {
  GET_PIPELINES_ITEMS_REQUEST,
  PIPELINES_ITEMS_SUCCESS,
  PIPELINES_ITEMS_FAILURE,
  MOVE_PIPELINE_ITEM,
} from '../actions';
// Types
import { IPipelineItem } from '../types/pipeline';

type PipelinesItemsState = {
  loading: boolean,
  data: IPipelineItem[],
  error: ?Error,
};

const PipelinesItemsInitialState: PipelinesItemsState = {
  loading: false,
  data: null,
  error: null,
};

const pipelinesItems = handleActions(
  {
    [GET_PIPELINES_ITEMS_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [PIPELINES_ITEMS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    [PIPELINES_ITEMS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [MOVE_PIPELINE_ITEM]: (state, action) => {
      const { itemId, sourcePipeline, targetPipeline } = action.payload;
      const newData = state.data.map(item => {
        if (item.id === itemId) {
          return { ...item, leadState: targetPipeline };
        }
        return item;
      });
      return {
        ...state,
        data: newData,
      };
    },
  },
  PipelinesItemsInitialState,
);

const reducers = combineReducers({
  pipelinesItems,
});

export default reducers;
